<template>
  <v-card class="elevation-12">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>Регистрация</v-toolbar-title>
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon large to="/login" v-on="on">
            <v-icon>mdi-home</v-icon>
          </v-btn>
        </template>
        <span>На главную</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-form>
        <v-text-field
          v-model.trim="email"
          :counter="60"
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
          :error-messages="emailErrors"
          label="E-mail"
          name="email"
          prepend-icon="mdi-email"
          type="text"
          hint="Адрес электронной почты для входа и для уведомлений"
        ></v-text-field>
        <v-text-field
          v-model.trim="name"
          :counter="60"
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
          :error-messages="nameErrors"
          label="Имя"
          name="name"
          prepend-icon="mdi-account"
          type="text"
          hint="Ваше Имя на русском языке"
        ></v-text-field>
        <v-text-field
          v-model.trim="telephone"
          :counter="60"
          @input="$v.telephone.$touch()"
          @blur="$v.telephone.$touch()"
          :error-messages="telephoneErrors"
          label="Телефон"
          name="telephone"
          prepend-icon="mdi-cellphone-iphone"
          type="text"
        ></v-text-field>
        <v-text-field
          v-model.trim="password"
          :counter="30"
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"
          :error-messages="passwordErrors"
          id="password"
          label="Пароль"
          name="password"
          prepend-icon="mdi-lock"
          type="password"
        ></v-text-field>

        <v-text-field
          v-model="password2"
          :counter="30"
          @input="$v.password2.$touch()"
          @blur="$v.password2.$touch()"
          :error-messages="password2Errors"
          id="password2"
          label="Пароль еще раз"
          name="password2"
          prepend-icon="mdi-lock"
          type="password"
        ></v-text-field>

        <v-checkbox
          v-model="checkbox"
          :error-messages="checkboxErrors"
          label="Руководствуясь Федеральным законом от 27 июня 2006 года № 152 ФЗ «О персональных данных» даю согласие на обработку моих персональных данных, необходимых для осуществления технологического присоединения энергопринимающих устройств."
          required
          @change="$v.checkbox.$touch()"
          @blur="$v.checkbox.$touch()"
        ></v-checkbox>
        <vue-recaptcha
          ref="recaptcha"
          size="invisible"
          :sitekey="sitekey"
          @verify="register"
          @expired="onCaptchaExpired"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click.prevent="registerHandler"
        :loading="loading"
        :disabled="loading"
        >Регистрация</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { sendRequest } from "../utils/graphql";
import { VALID_ERRORS } from "../utils/mainConsts.js";
import { mapMutations } from "vuex";
import VueRecaptcha from "vue-recaptcha";

import {
  required,
  minLength,
  maxLength,
  sameAs,
  email,
} from "vuelidate/lib/validators";

export default {
  name: "register",
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
  data: () => ({
    name: "",
    email: "",
    telephone: "",
    password: "",
    password2: "",
    sitekey: "ВАШ SITE KEY",
    checkbox: false,
    loading: false,
  }),
  validations: {
    checkbox: {
      checked(val) {
        return val;
      },
    },
    name: {
      maxLength: maxLength(60),
    },
    telephone: {
      maxLength: maxLength(60),
    },
    email: {
      required,
      email,
      maxLength: maxLength(60),
    },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(30),
    },
    password2: {
      required,
      match: sameAs("password"),
    },
  },
  computed: {
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push(VALID_ERRORS.personal);
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength && errors.push(VALID_ERRORS.maxLength(60));
      return errors;
    },
    telephoneErrors() {
      const errors = [];
      if (!this.$v.telephone.$dirty) return errors;
      !this.$v.telephone.maxLength && errors.push(VALID_ERRORS.maxLength(60));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(VALID_ERRORS.email);
      !this.$v.email.maxLength && errors.push(VALID_ERRORS.maxLength(60));
      !this.$v.email.required && errors.push(VALID_ERRORS.required);
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength && errors.push(VALID_ERRORS.minLength(6));
      !this.$v.password.maxLength && errors.push(VALID_ERRORS.maxLength(30));
      !this.$v.password.required && errors.push(VALID_ERRORS.required);
      return errors;
    },
    password2Errors() {
      const errors = [];
      if (!this.$v.password2.$dirty) return errors;
      !this.$v.password2.match && errors.push(VALID_ERRORS.notMatch);
      !this.$v.password2.required && errors.push(VALID_ERRORS.required);
      return errors;
    },
  },
  methods: {
    ...mapMutations(["setMessageData"]),

    register(recaptchaToken) {
      // axios.post('https://yourserverurl.com/register', {
      //   email: this.email,
      //   password: this.password,
      //   recaptchaToken: recaptchaToken
      // })
    },

    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },

    goToLink(link) {
      this.$router.push(link);
    },

    registerHandler() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const query = `
          mutation CreateNewPartner($userInput: UserInput!) {
            createUser(userInput: $userInput) {
              _id
            }
          }
        `;

        const variables = {
          userInput: {
            email: this.email,
            name: this.name,
            password: this.password,
            agreement: this.checkbox,
          },
        };

        try {
          this.loading = true;
          sendRequest("", query, variables, (res) => {
            this.loading = false;
            if (res.errors) {
              const errorText = res.errors[0].data.reduce((summ, curr) => {
                return summ + curr.message + ", ";
              });
              this.setMessageData({
                message: errorText.message,
                type: "snackbar",
              });
            } else {
              this.goToLink("/login");
              this.setMessageData({
                message: "Регистрация прошла успешно",
                type: "snackbar",
              });
            }
          });
        } catch (err) {
          console.log(err);
        }
      }
    },
  },
};
</script>

<style></style>
